var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"innerContent",staticStyle:{"height":"calc(100vh)","width":"100%","min-width":"85%","max-width":"100%","background":"var(--v-greyRaised-base) !important"}},[_c('v-row',{staticClass:"ma-1 pa-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"3"}},[_c('v-card',{staticClass:"infoCards mx-1",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('TransportDetail',{attrs:{"booking":_vm.booking}})],1)],1),_c('v-card',{staticClass:"infoCards mx-1 mt-2 pt-1",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('v-list',{key:_vm.transportKey,staticClass:"my-0 py-0",attrs:{"dense":"","subheader":""}},[_c('v-subheader',{staticStyle:{"font-size":"14px"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("local_shipping")]),_vm._v("Transport Locations ")],1),_c('v-divider'),_vm._l((_vm.transportLocations),function(location,index){return _c('v-list-item',{key:index,staticStyle:{"height":"30px"}},[_c('v-list-item-action',[_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(location.count))])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(location.name)+" ")]),_c('v-list-item-subtitle',{staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(location.description)+" ")])],1)],1)}),(_vm.transportLocations.length == 0)?_c('v-list-item',[_c('v-list-item-content',{staticClass:"text-center"},[_c('span',{staticStyle:{"color":"grey","font-size":"11px"}},[_vm._v("No locations set")])])],1):_vm._e()],2)],1)],1)],1),(
        _vm.booking.sailingSchedule &&
        _vm.booking.sailingSchedule.sailingScheduleLegs.length > 0
      )?_c('v-col',{staticClass:"ml-0 pl-1",attrs:{"cols":"12","sm":"12","md":"8","lg":"9"}},[_c('v-card',{staticClass:"infoCards mb-0 pb-0",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('v-list',{staticClass:"my-0 py-0",attrs:{"dense":"","subheader":""}},[_c('v-subheader',{staticStyle:{"font-size":"14px"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("track_changes")]),_vm._v("Vessel Tracking ")],1),_c('v-divider'),_c('v-row',[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12","sm":"4"}},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.booking.sailingSchedule.sailingScheduleLegs),function(leg){return _c('v-list-item',{key:leg.id},[_c('v-list-item-action',{staticClass:"mr-1"},[_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(leg.leg))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-chip',{staticClass:"primaryText--text",staticStyle:{"border":"none"},attrs:{"small":"","outlined":"","label":""}},[_c('v-avatar',{attrs:{"size":"24","left":""}},[_c('v-img',{attrs:{"contain":"","src":`https://cdn.loglive.io/flags/4x3/${leg.portOfLoadValue
                                .substring(0, 2)
                                .toLowerCase()}.svg`}})],1),_vm._v(_vm._s(leg.portOfLoadValue)+" ")],1),_c('v-icon',{staticClass:"mx-3",attrs:{"color":"grey","x-small":""}},[_vm._v("east")]),_c('v-chip',{staticClass:"primaryText--text",staticStyle:{"border":"none"},attrs:{"small":"","outlined":"","label":""}},[_c('v-avatar',{attrs:{"size":"24","left":""}},[_c('v-img',{attrs:{"contain":"","src":`https://cdn.loglive.io/flags/4x3/${leg.portOfDischargeValue
                                .substring(0, 2)
                                .toLowerCase()}.svg`}})],1),_vm._v(_vm._s(leg.portOfDischargeValue)+" ")],1)],1),_c('v-list-item-subtitle',{staticStyle:{"font-size":"11px"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":"","color":"grey"}},[_vm._v("directions_boat")]),_vm._v(" "+_vm._s(leg.vesselDescription)),(leg.voyage)?_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(leg.voyage))]):_vm._e()],1),_c('v-list-item-subtitle',{staticStyle:{"font-size":"11px"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":"","color":"grey"}},[_vm._v("schedule")]),_vm._v(" "+_vm._s(_vm.$Format.formatDate(leg.etd).isoFormat)+" - "+_vm._s(_vm.$Format.formatDate(leg.eta).isoFormat)+" ")],1)],1)],1)}),1)],1),_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('gmap-map',{key:_vm.refreshMap,ref:"mapRef",staticClass:"ma-0 pa-0 theMap",staticStyle:{"width":"100%","height":"400px"},attrs:{"zoom":_vm.zoom,"id":"map","options":{ styles: _vm.mapStyle, disableDefaultUI: true },"center":_vm.mapCenter},on:{"update:zoom":function($event){_vm.zoom=$event},"update:center":function($event){_vm.mapCenter=$event}}},[(_vm.routes && _vm.routes.legs)?_c('div',[_vm._l((_vm.routes.legs),function(path,index){return _c('gmap-polyline',{key:index,ref:"polyline",refInFor:true,attrs:{"path":path.pathData,"editable":false,"options":{
                        strokeColor: _vm.legColours[index] ?? '#2196f3',
                        strokeOpacity: 0.9,
                        strokeWeight: 3,
                      }}})}),(_vm.booking.vessel)?_c('gmap-custom-marker',{attrs:{"alignment":"center","marker":{
                        lat: _vm.booking.vessel.latitude,
                        lng: _vm.booking.vessel.longitude,
                      }}},[_c('div',[_c('div',{staticClass:"pulse"},[_c('v-icon',{style:({
                              rotate: `${_vm.booking.vessel.course - 45}deg`,
                              'z-index': 10000,
                            }),attrs:{"color":"teal"}},[_vm._v("near_me")])],1)])]):_vm._e()],2):_vm._e()])],1)],1)],1)],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }